main {
  min-height: 80vh;
}

h3 {
  padding: 1rem 0;
}

h1 {
  font-size: 1.8rem;
  padding: 1rem 0;
}

h2 {
  font-size: 1.4rem;
  padding: 0.5rem 0;
}



a {
  color: rgba(0, 0, 0, 0.854);
  text-decoration: none;
}

.black-link {
  color: black;
}

.black-link:hover {
  color: black;
}

.rating span {
  margin: 0.1rem;
}

.rating svg {
  color: #f8e825
}

.rating text {
  font-size: 0.8rem;
  font-weight: 600;
  padding-left: 0.5rem;
}

/* carousel */
.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: flex;
}
.carousel-caption {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  padding-top: 0;
  padding-bottom: 0;
}

.carousel-caption h2 {
  color: #fff;
}
.carousel-indicators{
  margin-bottom: 0;
}
.carousel img {
  height: auto;
  max-height: 400px;
  width: 100%;
  padding: 20px;
  margin: 20px auto;
  border-radius: 10%;
  object-fit: contain;
}
.carousel a {
  margin: 0 auto;
}

.custom-pagination {
  background-color: #fff; /* Set your background color for the pagination */
  margin-top: 20px;
}

.custom-pagination .page-link {
  color: #000; /* Set your text color for the pagination items */
}

.custom-pagination .page-item.active .page-link {
  background-color: #000; /* Set your background color for the active page */
  border-color: #000; /* Set your border color for the active page */
  color: #fff; /* Set your text color for the active page */
}

.product-name {
  height: 3.6em;
}

/* Product Title */
.product-title {
  font-size: 1rem;
  line-height: 1.2rem;
  max-height: 3.6rem; /* Limit the title to 3 lines */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  margin-top: 0; /* Ensure no top margin */
  padding-top: 0; /* Ensure no top padding */
}

/* Default product image size */
.product-image {
  width: 100%; /* Makes sure the image fits inside the card */
  height: 250px; /* Fixed height for consistency */
  object-fit: contain; /* Ensure the whole image is visible */
  background-color: #fff; /* Optional: Adds a background color behind the image */
}

/* Align prices consistently */
.product-price {
  font-size: 1.2rem;
  font-weight: 650;
  padding-top: 0.5rem;
  margin-top: auto; /* Push the price to the bottom of the card */
  text-align: center; /* Ensure it's centered */
  padding-top: 0.5rem; /* Reduce padding */

}

/* Card body style to ensure consistent layout */
.card-body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Ensure content starts from the top */
  align-items: stretch; /* Avoid centering or squeezing content */
  height: 100%;
}

/* Make the card a flex container */
.product-card {
  display: flex;
  flex-direction: column;
  height: 100%; /* Ensure the card fills the available vertical space */
  max-width: 100%; /* Avoid shrinking too much */

}

/* Allow the card body to grow and fill space */
.product-card .card-body {
  flex: 1; /* Flex-grow to fill remaining space */
  display: flex;
  flex-direction: column;
}

.brand-text {
  font-size: 1.5rem;
}

.navbar-dark, .navbar[data-bs-theme=dark] {
  --bs-navbar-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-hover-color: rgba(255, 255, 255, 1);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%28255, 255, 255, 0.55%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e);
}

.payment-logos-container {
  background-color: #fff;
  padding: 20px 0;
  width: 100%;
  overflow-x: hidden; /* Ensures no horizontal scrolling */
}

.logos-wrapper {
  display: flex;
  justify-content: space-between; /* Space out logos evenly */
  align-items: center;
  flex-wrap: wrap; /* Allow wrapping if needed, but keep logos small */
  gap: 10px;
  max-width: 100%; /* Prevent overflow */
}

.payment-logo {
  max-width: 10%; /* Each logo will take 10% of the container width */
  height: auto;
  flex-shrink: 1;
  transition: max-width 0.3s ease;
}

.accordion-button:not(.collapsed) {
  background-color: transparent; /* No background color */
  color: #343a40; /* Keep the text color (adjust if needed) */
  border: none; /* Remove border if you don't want any */
  border-bottom: 1px solid #dee2e6; /* Keep the accordion border color */
  transition: none; /* Disable transition for borders */

}

.accordion-button:focus {
  z-index: 3;
  outline: 0;
  box-shadow: none;
}

.form-check-input:checked {
  background-color: #212529;
  border-color: #212529;
  --bs-btn-hover-border-color: none;
}

.form-check-input:focus {
  border-color: #dee2e6 !important;
  outline: none !important;
  box-shadow: none !important;
}

.form-check-input:active,
.form-check-input:checked,
.form-check-input:checked:focus,
.form-check-input:checked:active {
  outline: none;
  box-shadow: none;
}




@media (max-width: 1200px) {
  .payment-logo {
    max-width: 9%;
  }
}

@media (max-width: 768px) {
  .payment-logo {
    max-width: 8%;
  }

}

@media (max-width: 576px) {
  .payment-logo {
    max-width: 7%;
  }

}

@media (max-width: 400px) {
  .payment-logo {
    max-width: 6%;
  }
}

/* Hide the toggle sign on larger screens */
@media (min-width: 768px) {
  .toggle-sign {
    display: none;
  }
}

/* Prefooter Styles */
.pre-footer {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
  color: rgba(255, 255, 255, 0.75); /* Text color similar to the navbar */
  padding: 2rem 0; /* Add some padding for spacing */
}

.pre-footer h6 {
  color: #fff; /* Heading color white */
  cursor: pointer;
  font-size: 1rem;
  font-weight: 450;
}

.pre-footer a {
  color: rgba(255, 255, 255, 0.75); /* Link color similar to the navbar */
  text-decoration: none;
}

.pre-footer a:hover {
  color: rgba(255, 255, 255, 1); /* Hover color matches the hover in your navbar */
}

.pre-footer ul {
  padding-left: 0; /* Remove left padding */
}

.pre-footer ul li {
  list-style: none; /* Remove bullet points */
  margin-bottom: 0.5rem; /* Add some space between list items */
}

.prefooter {
  font-family: var(--bs-body-font-family);
}

/* Toggle sign styling */
.toggle-sign {
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.75);
  margin-left: 0.5rem;
}

/* Collapse button style */
.collapse-button {
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.75);
  font-size: 1rem;
}

/* Hide the toggle sign on larger screens */
@media (min-width: 768px) {
  .toggle-sign {
    display: none;
  }
  
  /* Ensure lists are always visible on larger screens */
  .pre-footer ul {
    display: block !important;
  }

}





@media (max-width: 1200px) {
  .carousel-caption h2 {
    font-size: 2.5vw;
  }
/*   .product-title {
    font-size: 1rem;
    line-height: 1.4rem;
    height: auto;
  } */

  /* Reduce card body height */
  .card-body {
    min-height: 100px; /* Smaller height on small screens */
  }

  /* Adjust image height for smaller screens */
  .product-image {
    max-height: 150px; /* Reduce image height on smaller screens */
  }

  /* Adjust the title font size and height for smaller screens */
  .product-title {
    font-size: 0.9rem;
    line-height: 1rem;
    height: 2rem; /* Reduce title height for smaller screens */
  }

  /* Reduce the price font size for smaller screens */
  .product-price {
    font-size: 1rem;
  }
}